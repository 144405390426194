.exam-info-page {
  height: 100vh;
  background-image: url("../assets/wust.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    border-left: 4px solid #1aad19;
    padding-left: 10px;
  }

  .container {
    box-sizing: border-box;
    width: 90%;
    max-width: 400px;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    max-height: 80vh;
    overflow-y: auto;

    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #333;
      text-align: center;
    }

    .input-group {
      margin-bottom: 20px;

      .input-label {
        font-size: 16px;
        color: #333;
        display: block;
        margin-bottom: 5px;
      }

      .input-field {
        width: 100%;
        border: none;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 10px;
        font-size: 16px;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      }
    }

    .query-button,
    .back-button,
    .floor-button {
      display: block;
      width: 100%;
      background-color: #1aad19;
      color: #fff;
      border: none;
      border-radius: 8px;
      padding: 10px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      margin-top: 20px;

      &:hover {
        background-color: #148f14;
      }
    }

    .floor-button {
      background-color: #1f9cf0;

      &:hover {
        background-color: #1a89d9;
      }
    }

    .exam-info {
      margin-top: 20px;

      .info-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

        .info-label {
          font-size: 16px;
          font-weight: bold;
          color: #333;
          margin-right: 10px;
        }

        .info-value {
          font-size: 16px;
          color: #666;
        }
      }

      .img-container {
        border-radius: 10px;
        overflow: hidden;
        margin-top: 20px;

        img {
          width: 100%;
          height: auto;
          border-radius: 10px;
          cursor: pointer;
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  .error-message {
    color: #ff0000;
    margin-top: 10px;
    text-align: center;
  }
}