.image-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
  
    .modal-content {
      position: relative;
      max-width: 90%;
      max-height: 90%;
  
      img {
        width: 100%;
        height: auto;
        max-height: 90vh;
        object-fit: contain;
      }
  
      .close {
        position: absolute;
        top: -30px;
        right: -30px;
        color: #f1f1f1;
        font-size: 40px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }